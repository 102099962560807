html,
body {
  background: #fafbfd;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2214.489044933872673%22%20height%3D%2214.489044933872673%22%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Ccircle%20cx%3D%220.5%22%20cy%3D%220.5%22%20r%3D%220.5%22%20fill%3D%22%2381818a%22%20%2F%3E%3C%2Fsvg%3E");
}

#main {
  padding: 0 0 20px;
}

#vector {
  margin: 0 auto;
}
